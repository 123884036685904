import { useCallback } from "react";
import cn from "@/libs/cn";
import useRouter from "@/hooks/useRouter";
import useGlobalContext from "@/context/GlobalContext";
import Button from "@/controls/Button";
import {
  useDataPublicFeaturedProducts,
  useDataPublicFeaturedTags,
} from "@/modules/public/hooks/useData";
import routesPublic from "@/modules/public/constants/routesPublic";
import CardProduct from "./CardProduct";

import styles from "./OurProducts.module.scss";

interface TagButton {
  id: any;
  title: string;
  onClick: (e: any) => void;
  className?: string;
}

const TagButton = ({ id, title, onClick, className }: TagButton) => {
  return (
    <div
      className={cn(styles.TagButton, className)}
      onClick={() => onClick(id)}
    >
      {title}
    </div>
  );
};

interface ListTags {
  data: any[];
  onClick: (e: any) => void;
}

const ListTags = ({ data, onClick }: ListTags) => {
  const { translate } = useGlobalContext();

  return (
    <div className={styles.ListTags}>
      {data?.slice(0, 5).map((item) => (
        <TagButton key={item.id} {...item} onClick={onClick} />
      ))}
      <TagButton
        id={null}
        key="more"
        className={styles.tagMore}
        title={translate("Show more")}
        onClick={onClick}
      />
    </div>
  );
};

const OurProducts = () => {
  const router = useRouter();
  const { translate } = useGlobalContext();
  const products = useDataPublicFeaturedProducts();
  const tags = useDataPublicFeaturedTags();

  const onClickTag = useCallback(
    (tagId: number) => {
      router.push(`${routesPublic.category()}?tags=${tagId || ""}`);
    },
    [router]
  );

  if (!products.data || products.error || !tags.data || tags.error) {
    return null;
  }

  return (
    <div className={styles.OurProducts}>
      <div className={styles.header}>
        <h1 className={styles.title}>{translate("Featured products")}</h1>
        <ListTags data={tags.data} onClick={onClickTag} />
      </div>
      <div className={styles.list}>
        {products.data.slice(0, 4).map((item: any) => (
          <div key={item.id} className={styles.wrapItem}>
            <CardProduct {...item} />
          </div>
        ))}
      </div>

      <Button
        color="primary"
        className={styles.btnMore}
        href={routesPublic.products()}
      >
        {translate("Show more")}
      </Button>
    </div>
  );
};

export default OurProducts;
