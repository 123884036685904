import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import LoaderCircle from "@/controls/LoaderCircle";
import cn from "@/libs/cn";

import styles from "./styles.module.scss";

interface CircleButton {
  faIcon: any;
  onClick?: (e: any) => void;
  active?: boolean;
  title: string;
  isProcessing?: boolean;
  className?: string;
}

const CircleButton = ({
  faIcon,
  onClick: onClickTop = () => {},
  active,
  title,
  isProcessing,
  className,
}: CircleButton) => {
  const onClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      onClickTop(e);
    },
    [onClickTop]
  );

  return (
    <button
      className={cn(
        styles.CircleButton,
        active && styles.active,
        title && styles.tooltip,
        className
      )}
      onClick={onClick}
      aria-hidden="true"
      data-title={title}
    >
      {/* @ts-ignore */}
      {typeof faIcon === "string" ? <FontAwesomeIcon icon={faIcon} /> : faIcon}
      {isProcessing && <LoaderCircle size={24} className={styles.loader} />}
    </button>
  );
};

export default CircleButton;
