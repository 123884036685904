import useGlobalContext from "@/context/GlobalContext";
import { formatPrice } from "@/utils/format";
import Button from "@/controls/Button";

import styles from "./styles.module.scss";

interface CardSale {
  background_image: string;
  background_color: string;
  title: string;
  description: string;
  currency: { symbol: string };
  price: string;
}

const CardSale = ({
  background_image,
  background_color,
  title,
  description,
  currency,
  price,
}: CardSale) => {
  const { translate } = useGlobalContext();

  return (
    <div
      className={styles.CardSale}
      style={{
        backgroundImage: `url(${background_image})`,
        backgroundColor: background_color,
      }}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.price}>{`${translate("from")} ${
        currency?.symbol
      }${formatPrice(price)}`}</div>
      <div className={styles.button}>
        <Button color="primary">{translate("Shop now")}</Button>
      </div>
    </div>
  );
};

export default CardSale;
