import { DOMAINE } from "@/constants/common";
import { NextPageContext } from "next";
import { parsingUserAgent } from "./userAgent";

export const getServerDefaultProps = async (context: NextPageContext) => {
  const ua = parsingUserAgent(context.req);

  let dictionary = null;
  try {
    const res = await fetch(`${DOMAINE}/api/translations/${context.locale}/`);

    const json = await res?.json();
    if (json) {
      dictionary = json;
    }
  } catch (err) {}

  return {
    locales: context.locales,
    locale: context.locale,
    dictionary,
    device: {
      // undefined, console, mobile, tablet, smarttv, wearable, embedded
      type: ua.device.type || null,
    },
  };
};
