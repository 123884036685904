import cn from "@/libs/cn";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import styles from "./styles.module.scss";

const isSSR = typeof window === "undefined";

interface Slide {
  children: JSX.Element[];
  onSelect?: (idx: number) => void;
  classNameItem?: string;
  autoMargin?: boolean;
  hideWrapBorder?: boolean;
  hideArrows?: boolean;
}

const Slide = ({
  children = [],
  onSelect = () => {},
  classNameItem = "",
  autoMargin,
  hideWrapBorder = false,
  hideArrows,
}: Slide) => {
  const refList = useRef(null);
  const [widthSlide, setWidthSlide] = useState(null);
  const [hover, setHover] = useState(false);
  const [active, setActive] = useState(0);
  const [widthChild, setWidthChild] = useState(100);

  const setInitValues = useCallback(
    (el: any) => {
      if (children.length) {
        const rect = el.children[0].getBoundingClientRect();
        setWidthChild(rect.width);
      }
    },
    [children.length]
  );

  useEffect(() => {
    if (refList.current && widthSlide) {
      setInitValues(refList.current);
    }
  }, [setInitValues, widthSlide, children]);

  const resizeObserver = useMemo(() => {
    if (isSSR) {
      return;
    }

    return new ResizeObserver((entries) => {
      if (refList?.current) {
        // @ts-ignore
        const rect = refList.current.getBoundingClientRect();
        setTimeout(() => setWidthSlide(rect.width), 0);
      }
    });
  }, []);

  useEffect(() => {
    if (refList?.current) {
      if (!isSSR) {
        // @ts-ignore
        resizeObserver.observe(document.scrollingElement);
      }
    }
  }, [resizeObserver]);

  const onLeft = useCallback(() => {
    // @ts-ignore
    refList.current.scrollLeft -= widthChild * 2;
  }, [widthChild]);

  const onRight = useCallback(() => {
    // @ts-ignore
    refList.current.scrollLeft += widthChild * 2;
  }, [widthChild]);

  return (
    <div
      className={cn(styles.Slide, autoMargin && styles.autoMargin)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ width: widthSlide || undefined }}
    >
      <div ref={refList} className={styles.list}>
        {!!widthSlide &&
          children.map((item, idx) => (
            <div
              key={idx}
              className={cn(
                styles.wrapItem,
                classNameItem,
                active === idx && styles.active
              )}
              style={{ borderWidth: hideWrapBorder ? 0 : undefined }}
              onClick={() => {
                setActive(idx);
                onSelect(idx);
              }}
            >
              {item}
            </div>
          ))}
      </div>
      {!hideArrows && (
        <div
          className={cn(styles.arrowLeft, hover && styles.show)}
          onClick={onLeft}
        />
      )}
      {!hideArrows && (
        <div
          className={cn(styles.arrowRight, hover && styles.show)}
          onClick={onRight}
        />
      )}
    </div>
  );
};

export default Slide;
