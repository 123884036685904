import { NextPageContext } from "next";
import dynamic from "next/dynamic";
import { getServerDefaultProps } from "@/utils/server";
import BlockMaxWidth from "@/modules/layout/components/BlockMaxWidth";
import Information from "../components/variant-one/Information";
import NewStores from "../components/variant-one/NewStores";
import OurProducts from "../components/variant-one/OurProducts";
import Sales from "../components/variant-one/Sales";
import TopCategories from "../components/variant-one/TopCategories";
import Waiting from "@/components/basic/Waiting";

const Banner = dynamic(() => import("../components/variant-one/Banner"), {
  suspense: true,
});

const HomePage = () => {
  return (
    <>
      <Waiting style={{ height: 440 }}>
        <Banner />
      </Waiting>
      <Information />
      <BlockMaxWidth>
        <Sales />
        <TopCategories />
        <OurProducts />
        <NewStores />
      </BlockMaxWidth>
    </>
  );
};

export default HomePage;

export async function getServerSideProps(context: NextPageContext) {
  const defaultProps = await getServerDefaultProps(context);

  return {
    props: {
      ...defaultProps,
    },
  };
}
