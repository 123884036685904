import Link from "@/components/basic/Link";
import cn from "@/libs/cn";
import { slugByName } from "@/modules/public/hooks/useCategorySlug";
import { useDataPublicCategory } from "@/modules/public/hooks/useData";

import styles from "./styles.module.scss";

interface TextCategory {
  id: number;
  baseRoute?: string;
  className?: string;
}

const TextCategory = ({ id, baseRoute = "", className }: TextCategory) => {
  const { data, error } = useDataPublicCategory(id);

  if (!data || error) {
    return null;
  }

  return (
    <div className={cn(styles.TextCategory, className)}>
      <Link href={`${baseRoute}${slugByName(data.name)}/`}>{data.name}</Link>
    </div>
  );
};

export default TextCategory;
