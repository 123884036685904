import useGlobalContext from "@/context/GlobalContext";
import { useDataPublicSales } from "@/modules/public/hooks/useData";
import CardSale from "./CardSale";

import styles from "./Sales.module.scss";

const Sales = () => {
  const { data, error } = useDataPublicSales();
  const { currency } = useGlobalContext();

  if (!data || error) {
    return null;
  }

  return (
    <div className={styles.Sales}>
      {data.map((item: any) => (
        <CardSale key={item.id} {...item} currency={currency} />
      ))}
    </div>
  );
};

export default Sales;
