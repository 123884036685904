import Image from "@/components/basic/Image";
import Link from "@/components/basic/Link";
import routesPublic from "@/modules/public/constants/routesPublic";
import { useSlugByCategory } from "@/modules/public/hooks/useCategorySlug";

import styles from "./styles.module.scss";

interface CardTopCategory {
  id: number;
  icon: string;
  name: string;
}

const CardTopCategory = ({ id, icon, name }: CardTopCategory) => {
  const slug = useSlugByCategory(id);

  return (
    <Link
      href={slug ? `${routesPublic.category()}${slug}/` : ""}
      className={styles.CardTopCategory}
    >
      <Image
        className={styles.icon}
        src={icon}
        alt={name}
        width={80}
        height={80}
        objectFit="contain"
      />
      <div className={styles.name}>
        <div className={styles.text}>{name}</div>
      </div>
    </Link>
  );
};

export default CardTopCategory;
