import { useCallback, useState } from "react";
import cn from "@/libs/cn";
import Image from "@/components/basic/Image";
import Link from "@/components/basic/Link";
import useRouter from "@/hooks/useRouter";
import routesPublic from "@/modules/public/constants/routesPublic";

import styles from "./styles.module.scss";
import { useDataPublicStoreTag } from "@/modules/public/hooks/useData";

interface TextTag {
  id: number;
}

const TextTag = ({ id }: TextTag) => {
  const { data, error } = useDataPublicStoreTag(id);

  if (!data || error) {
    return null;
  }

  return (
    <Link
      href={`${routesPublic.stores()}?tags=${id}`}
      className={styles.TextTag}
    >
      {data.title}
    </Link>
  );
};

interface CardStore {
  id: number;
  title: string;
  banner: string;
  logo: string;
  main_address: {
    country?: string;
    city?: string;
    province?: string;
  };
  tags: any[];
}

const CardStore = (props: CardStore) => {
  const { id, title, banner, logo, main_address, tags } = props;
  const { country, city, province } = main_address;

  const router = useRouter();
  const [show, setShow] = useState(false);

  const urlStore = routesPublic.store(id);

  const location = [country, city, province]
    .filter((item) => !!item)
    .join(", ");

  const onClick = useCallback(() => router.push(urlStore), [router, urlStore]);

  return (
    <div
      className={cn(styles.CardStore)}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div className={styles.wrapImage}>
        <Image
          src={banner}
          layout="fill"
          objectFit="cover"
          alt={title}
          className={cn(styles.img, show && styles.show)}
          onClick={onClick}
        />
        <div className={cn(styles.logo)}>
          <Image src={logo} alt={title} width={96} height={96} />
        </div>
      </div>
      <div className={styles.info}>
        <Link href={urlStore}>
          <div className={styles.name} onClick={onClick}>
            {title}
          </div>
        </Link>
        <div className={styles.location}>
          <Image
            src="/assets/location.svg"
            alt="location"
            width={12}
            height={20}
          />
          <div className={styles.text}>{location}</div>
        </div>
        <div className={styles.tags}>
          {tags.map((tag) => (
            <TextTag key={tag.id} id={tag.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardStore;
