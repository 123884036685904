import { useCallback } from "react";
import Slide from "@/components/Slide";
import useGlobalContext from "@/context/GlobalContext";
import { useDataPublicTopCategories } from "@/modules/public/hooks/useData";
import CardTopCategory from "./CardTopCategory";

import styles from "./TopCategories.module.scss";

const TopCategories = () => {
  const { translate } = useGlobalContext();
  const { data, error } = useDataPublicTopCategories();

  const renderItem = useCallback(
    (item: any, idx: number) => <CardTopCategory key={idx} {...item} />,
    []
  );

  if (!data || error) {
    return null;
  }

  return (
    <nav className={styles.TopCategories}>
      <h1 className={styles.title}>{translate("Shop by categories")}</h1>
      <nav className={styles.categories}>
        <Slide classNameItem={styles.item} autoMargin hideWrapBorder>
          {data.map(renderItem)}
        </Slide>
      </nav>
    </nav>
  );
};

export default TopCategories;
