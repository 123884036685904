import Image from "@/components/basic/Image";
import useGlobalContext from "@/context/GlobalContext";
import useRouter from "@/hooks/useRouter";
import cn from "@/libs/cn";
import { Product } from "@/modules/cart/hooks/useCart";
import routesPublic from "@/modules/public/constants/routesPublic";
import { getPictureURL } from "@/utils/data";
import {
  formatDiscount,
  formatFinalPrice,
  formatPrice,
  isZero,
} from "@/utils/format";
import { getDiscountPercent, getOldPrice, getRealPrice } from "@/utils/price";
import React, { useCallback, useState } from "react";
import CircleButton from "../CircleButton";
import TextCategory from "../TextCategory";

import styles from "./styles.module.scss";

interface CardProduct extends Product {
  id: number;
  name: string;
  price: any;
  final_price: any;
  main_pic: any;
  categories: any[];
  is_packed: boolean;
  items_in_pack: number;
  is_seasonal: boolean;
}

const CardProduct = (props: CardProduct) => {
  const {
    isMobileSize,
    translate,
    openModal,
    modules: { cart },
    currency: { symbol },
  } = useGlobalContext();

  const {
    id,
    name,
    price,
    final_price,
    main_pic,
    categories,
    is_packed,
    items_in_pack,
    is_seasonal,
    in_stock,
  } = props;
  const router = useRouter();
  const [show, setShow] = useState(false);

  const urlProduct = `${routesPublic.product(id)}`;

  const onClick = useCallback(
    () => router.push(urlProduct),
    [router, urlProduct]
  );

  const mainPic = getPictureURL(main_pic);

  const realPrice = getRealPrice(price, final_price);
  const oldPrice = getOldPrice(price, final_price);
  const discount = getDiscountPercent(price, final_price);

  return (
    <div
      className={cn(styles.CardProduct, !in_stock && styles.outStock)}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div className={styles.wrapImage}>
        <div onClick={onClick} className={cn(styles.img, show && styles.show)}>
          {!!mainPic && (
            <Image src={mainPic} layout="fill" alt={name} objectFit="contain" />
          )}
        </div>

        <div className={styles.label}>
          {!isZero(discount) && (
            <div className={styles.discount} data-title={translate("Discount")}>
              <div className={styles.count}>{formatDiscount(discount)}%</div>
            </div>
          )}
          {is_packed && (
            <div
              className={styles.pack}
              data-title={translate("Items in pack")}
            >
              <div className={styles.count}>{items_in_pack}X</div>
            </div>
          )}
          {is_seasonal && (
            <div
              className={styles.seasonable}
              data-title={translate("Is Seasonable")}
            >
              <Image
                src={`/assets/snow.png`}
                layout="fill"
                alt={translate("Is Seasonable")}
                objectFit="contain"
              />
            </div>
          )}
        </div>

        <div
          className={cn(styles.panel, (show || isMobileSize) && styles.show)}
        >
          {/* <CircleButton
            title="Quik view"
            faIcon="fa-regular fa-eye"
            onClick={() => openModal(<div></div>)}
          />
          <CircleButton title="Add to wishlist" faIcon="fa-regular fa-heart" /> */}
          <CircleButton
            title="Add to cart"
            faIcon="fa-solid fa-cart-shopping"
            active
            onClick={() => cart.addProductToCart(props, 1)}
            isProcessing={cart.isCartAddProcessing === id}
          />
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.categories}>
          {categories.map((item: any, idx: number) => (
            <React.Fragment key={item.id}>
              <TextCategory {...item} baseRoute={routesPublic.category()} />
            </React.Fragment>
          ))}
        </div>
        <div className={styles.name} title={name} onClick={onClick}>
          {name}
        </div>
        <div className={styles.wrapPrice}>
          {in_stock ? (
            <>
              <div className={styles.finalPrice}>
                {formatFinalPrice(realPrice)} {symbol}
              </div>
              {!isZero(oldPrice) && (
                <div className={styles.price}>
                  {formatPrice(oldPrice)} {symbol}
                </div>
              )}
            </>
          ) : (
            <div className={styles.notAvailable}>
              {translate("Out of stock")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
