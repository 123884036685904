import Image from "@/components/basic/Image";
import useGlobalContext from "@/context/GlobalContext";
import BlockMaxWidth from "@/modules/layout/components/BlockMaxWidth";

import styles from "./Information.module.scss";

const Information = () => {
  const { translate } = useGlobalContext();

  return (
    <nav className={styles.Information}>
      <BlockMaxWidth className={styles.maxWidth}>
        <div>
          <div className={styles.cardInfo}>
            <Image
              src="/assets/mock/info/shipping.svg"
              width={48}
              height={48}
              alt="shipping"
            />
            <div className={styles.wrap}>
              <div className={styles.title}>{`${translate(
                "Free Shipping"
              )}`}</div>
              <div className={styles.description}>{`${translate(
                "Free Shipping On All EU"
              )}`}</div>
            </div>
          </div>

          <div className={styles.cardInfo}>
            <Image
              src="/assets/mock/info/money.svg"
              width={48}
              height={48}
              alt="money"
            />
            <div className={styles.wrap}>
              <div className={styles.title}>{`${translate(
                "Money Returns"
              )}`}</div>
              <div className={styles.description}>{`${translate(
                "Return it Within 30 days"
              )}`}</div>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.cardInfo}>
            <Image
              src="/assets/mock/info/card.svg"
              width={48}
              height={48}
              alt="card"
            />
            <div className={styles.wrap}>
              <div className={styles.title}>{`${translate(
                "Security Payment"
              )}`}</div>
              <div className={styles.description}>{`${translate(
                "We Ensure Secure Payment"
              )}`}</div>
            </div>
          </div>

          <div className={styles.cardInfo}>
            <Image
              src="/assets/mock/info/support.svg"
              width={48}
              height={48}
              alt="support"
            />
            <div className={styles.wrap}>
              <div className={styles.title}>{`${translate(
                "Support 24/7"
              )}`}</div>
              <div className={styles.description}>{`${translate(
                "Contact Us 24 Hours A Day"
              )}`}</div>
            </div>
          </div>
        </div>
      </BlockMaxWidth>
    </nav>
  );
};

export default Information;
