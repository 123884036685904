import useGlobalContext from "@/context/GlobalContext";
import Button from "@/controls/Button";
import routesPublic from "@/modules/public/constants/routesPublic";
import { useDataPublicNewStoresMOCK } from "@/modules/public/hooks/useData";
import CardStore from "./CardStore";

import styles from "./NewStores.module.scss";

const NewStores = () => {
  const { translate } = useGlobalContext();
  const { data, error } = useDataPublicNewStoresMOCK();

  if (!data || error) {
    return null;
  }

  return (
    <div className={styles.NewStores}>
      <h1 className={styles.title}>{translate("New stores")}</h1>
      <div className={styles.list}>
        {data?.map((item: any) => (
          <CardStore key={item.id} {...item} />
        ))}
      </div>
      <Button
        color="primary"
        className={styles.btnMore}
        href={routesPublic.stores()}
      >
        {translate("Show more")}
      </Button>
    </div>
  );
};

export default NewStores;
